/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    ul: "ul",
    li: "li",
    a: "a",
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "api-handling"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "error handling patterns"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://axios-http.com/docs/example"
  }, "axios")), "\n"), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://dmitripavlutin.com/javascript-fetch-aasync-await/"
  }, "how to Fetch with javascript async await")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
